<template>
  <div class="view pa24">
    <div class="bgfff">
      <div class="d-flex m-l-10 p-t-20 w100p p-r-31 p-b-17">
        <el-form  :inline="true" label-width="80px">
          <el-row>
            <el-form-item label="标题名称">
              <el-input
                      type="text"
                      placeholder="请输入标题内容"
                      v-model="form.name"
                      style="width: 400px"
                      maxlength="10"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row class="mt10">
            <el-form-item label="标题图标">
              <el-upload
                      list-type="picture-card"
                      :disabled="type == 0 ? true : false"
                      :action="uploadUrl"
                      :show-file-list="false"
                      :auto-upload="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload">
                <img v-if=" form.logo && form.logo != ''" :src="form.logo" width="100%;" height="100%;" class="avatar">
                <i v-else class="el-icon-plus el-upload--picture-card"></i>
                <div slot="tip" class="el-upload__tip">上传图片格式png，jpeg，jpg。 大小不超过2M,图片大小100px*100px</div>
              </el-upload>

            </el-form-item>
          </el-row>

          <el-form-item label="海报图片">
            <div v-for="(item ,index) in form.detailRequests " :key="index">
<!--                v-if="item.formType == 'IMAGE'"-->
              <el-row  class="commentForm">
                <el-row>
                  <el-form-item label="标题" class="mb10">
                    <el-input
                            type="text"
                            placeholder="请输入图片标题"
                            v-model="item.title"
                            style="width: 400px"
                            maxlength="10"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="人数" class="mb10">
                    <el-input
                      type="text"
                      placeholder="请输入虚拟使用人数"
                      v-model="item.useNum"
                      oninput ="value=value.replace(/[^0-9]/g,'')"
                      style="width: 400px"
                      maxlength="10"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="实际人数" class="mb10" v-if="item.actualUseNum">
                    <div  style="width: 400px" >{{item.actualUseNum}}</div>
                  </el-form-item>

                  <el-form-item >
                    <i class="el-icon-delete" @click="deleteCompoents(form.detailRequests,index)"></i>
                  </el-form-item>
                </el-row>
                <el-form-item label="图片">
                  <el-upload :auto-upload="true"
                             :disabled="type == 0 ? true : false"
                             :action="uploadUrl"
                             :show-file-list="false"
                             :data="item"
                             :on-success="(res,file)=>{handleAvatarSuccessList(item,res,file,index)}"
                             :before-upload="beforeAvatarUploadList">
                    <img v-if="item.imagePath" width="250px;" height="344px;" :src="item.imagePath" class="avatar">
                    <i  class="el-icon-plus el-upload--picture-card text-align fs12"></i>
                    <div slot="tip" class="el-upload__tip">上传图片格式png，jpeg，jpg。 大小不超过2M,图片大小750px*1034px</div>
                  </el-upload>
                </el-form-item>
              </el-row>
            </div>
          </el-form-item>

          <el-row style="margin-top: 10px;">
            <el-button type="primary" v-if="type != 0" icon="el-icon-circle-plus" style="background-color: $color-1;color: white" @click="selectEnter" >添加</el-button>
          </el-row>
          <el-row align="right"  v-if="type != 0" style="width: 800px;text-align: right; ">
            <el-button style="background-color: $color-1;color: white" @click="saveForm">保存</el-button>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryPoster,
  addPoster,
  updatePoster

} from "@/api/addedServices";
export default {
  data() {
    return {
      dialogVisible:false,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      options:[],
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      imageUrl:'',
      disabled:true,
      type:0,
      form:{
        name:'',
        logo:'',
        detailRequests:[
            {formType:'IMAGE',title:'',imagePath:"",useNum:''}
        ]
      },
      classificationId:''
    };
  },
  created(){
    this.type = this.$route.query.type;
    this.classificationId = this.$route.query.classificationId
    if(this.classificationId){
      this.getDetail(this.classificationId)
    }
  },
  methods:{
    handleAvatarSuccess(res, file){
      this.form.logo = this.ossUrl + res.data;
      console.log(this.form.logo )
      this.form.name = this.form.name;
    },
    beforeAvatarUpload(file){
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过 2MB!图片大小100px*100px');
        return false
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this;
      reader.onload = function () { //让页面中的img标签的src指向读取的路径
        var img = new Image()
        img.src = reader.result
        if (img.complete) {//如果存在浏览器缓存中
          if (img.width != 100 || img.height != 100) {
            that.$message.info("上传图片分辨率建议100px*100px,宽度不可超过100px，高度不超过100px!");
            that.form.logo = "";
            return false
          }
        } else {
          img.onload = function () {
            if (img.width != 100 || img.height != 100) {
              that.$message.info('上传图片分辨率建议100px*100px,宽度不可超过100px，高度不超过100px!');
              that.form.logo = "";
              return false
            }
          }
        }
      }
      return true;
    },
    handleAvatarSuccessList(item,res, file,index) {
      this.ossUrl + res.data;
      item.imagePath = this.ossUrl +  res.data;
      this.$set(this.form.detailRequests,'index',item.imagePath)
    },
    beforeAvatarUploadList(file){
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!图片大小750px*1034px');
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this;
      reader.onload = function () { //让页面中的img标签的src指向读取的路径
        var img = new Image()
        img.src = reader.result
        if (img.complete) {//如果存在浏览器缓存中
          if (img.width != 750 || img.height != 1034) {
            that.$message.info("上传图片分辨率建议750px*1034px,宽度不可超过750px，高度不超过1034px!");
            return false
          }
        } else {
          img.onload = function () {
            if (img.width != 750 || img.height != 1034) {
              that.$message.info('上传图片分辨率建议750px*1034px,宽度不可超过750px，高度不超过1034px!');
              return false
            }
          }
        }
      }

      return isLt2M;
    },
    deleteCompoents(detailRequests,index){
      detailRequests.splice(index,1);
    },
    //保存表单
    saveForm(){

      this.form.detailRequests.forEach((item,index)=>{
        item.serialNumber = index+1;
      })
      this.form.posterImages = this.form.detailRequests;
      if(this.form.classificationId){
        //编辑
        updatePoster( this.form)
        .then(res => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.go(-1)
          return

        });
      }else{
        //新增
        addPoster( this.form)
        .then(res => {
          this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$router.go(-1)
          //去列表

        });
      }
    },
    selectEnter(){
      if(this.form.detailRequests.length > 49){
        this.$message({
          message: "最多可添加50个",
          type: "warning",
        });
        return ;
      }
      const list = this.form.detailRequests;
      list.push( {formType:'IMAGE',title:'',imagePath:"",useNum:''});
      this.form.detailRequests = list;
      this.form.detailRequests.unshift()
      console.log(this.form.detailRequests)
    },
    getDetail(classificationId){
      if(classificationId){
        queryPoster({classificationId:classificationId})
        .then(res => {
          console.log(res)
          const details = this.form.detailRequests;
          if(!res.data.posterImages){
            res.data.posterImages = [];
          }
          this.form.name = res.data.name;
          this.form.logo = res.data.logo;
          this.form.classificationId = res.data.classificationId;
          if(res.data.posterImages){
            res.data.posterImages.forEach(image =>{
              image.formType = "IMAGE";
            })
            this.form.detailRequests = res.data.posterImages;
          }else{
            this.form.detailRequests = details;
            this.form.detailRequests.push( {formType:'IMAGE',title:'',useNum:'',imagePath:""});
          }
          console.log(this.form.detailRequests)
          //去列表
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btncss{
    background-color: $color-1;
    border: 1px $color-1 solid;
    color: white;
    margin-left: 20px;
  }
  tbody tr td {
    text-align: center;
  }
  .is-center{
    text-align: center;
  }
  .el-table th.is-center{
    text-align: -webkit-center !important;
  }
  .el-form-item{
    margin-bottom: 4px !important;
  }
  .commentForm{
    border: 1px solid #DCDFE6 !important;
    padding-top: 10px;
    margin-top: 10px;
    width: 530px;
  }
  .el-upload--picture-card{
    background-color: #fbfdff !important;
    border: 1px dashed #c0ccda !important ;
    border-radius: 6px !important;
    box-sizing: border-box !important;
    vertical-align: top !important;
  }
  /deep/ .el-upload--text {
    background-color: #fff;
    border: 0px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 250px;
    height: 344px;
    text-align: left;
    cursor: pointer;
}
</style>
